import React, { Component } from 'react'
import {
  Button,
  Input,
  Modal,
  SVGIcon,
  TextArea,
  ViewButtons,
  ViewContent,
  ViewHeader
} from 'react-ui'
import styled from 'styled-components'
import RemoveConfirmDialog from './RemoveConfirmDialog'

const ModalViewContent = styled(ViewContent)`
  max-height: calc(100vh - 288px);
`

class Form extends Component {
  state = {
    showModal: false,
    password: ''
  }

  componentDidUpdate(prevProps) {
    const { show, entry } = this.props
    if (prevProps.show !== show) {
      this.setState({
        showModal: show,
        password: ''
      })
    }
  }

  update = e => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  submitForm = e => {
    e.preventDefault()
    const { password } = this.state
    const { onSubmit } = this.props
    onSubmit(password)
  }

  closeForm = () => {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const { className, err } = this.props
    const { showModal, password } = this.state
    return (
      <div className={className}>
        <Modal
          show={showModal}
          attachToBody
          width='560'
          top='96'
          onClose={this.closeForm}
        >
          <ViewHeader>
            <h1>Passwort für Dashboard festlegen</h1>
          </ViewHeader>
          <ModalViewContent>
            <form onSubmit={this.submitForm}>
              <Input
                name='password'
                label='Passwort (min. 8 Zeichen)'
                err={err}
                value={password}
                onChange={this.update}
                autoFocus
              />
            </form>
          </ModalViewContent>
          <ViewButtons>
            <Button
              secondary='true'
              onClick={this.closeModal}
            >
              Abbrechen
            </Button>
            <Button
              onClick={this.submitForm}
            >
              Speichern
            </Button>
          </ViewButtons>
        </Modal>
      </div>
    )
  }
}

export default styled(Form)`  
  ${TextArea} {
    margin-bottom: 0;
  }
`
