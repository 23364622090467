const nanoTimeToStr = nanoTime => {
  return new Date(nanoTime / 1e6)
    .toLocaleString('de-DE', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
}

export default {
  nanoTimeToStr
}