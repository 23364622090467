import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Dashboard from './Dashboard'
import Admin from './Admin'
import Mobile from './Mobile'

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  overflow-y: auto;
`

class Views extends Component {
  render() {
    const { className } = this.props
    return (
      <div className={className}>
        <Switch>
          <Route
            path='/'
            exact
            component={Mobile}
          />
          <Route
            path='/dashboard'
            component={Dashboard}
          />
          <Route
            path='/admin'
            component={Admin}
          />
        </Switch>
      </div>
    )
  }
}

export default styled(Views)`
  display: flex;
  flex-direction: column;
`
