import React, {useState} from 'react'
import {Button, Input} from 'react-ui'
import styled from 'styled-components'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 32px;
  padding: 8px;
`

const PasswordInput = styled(Input)`
  margin: 0;
  margin-bottom: 8px;
  
  input {
    margin: 0;
  }
`

const SubmitButton = styled(Button)`
  margin: 0;
`

const Err = styled.div`
  margin-bottom: 8px;
  padding: 16px;
  background: ${p => p.theme.colors.err};
  color: #fff;
`

const PasswordForm = ({className, err, onSubmit}) => {
  const [password, setPassword] = useState('')

  const update = e => {
    const {value} = e.target
    setPassword(value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(password)
  }

  return (
    <div className={className}>
      <Form onSubmit={handleSubmit}>
        {err && (
          <Err>{err}</Err>
        )}
        <PasswordInput
          type='password'
          placeholder='Passwort'
          value={password}
          onChange={update}
        />
        <SubmitButton
          type='submit'
        >
          Einloggen
        </SubmitButton>
      </Form>
    </div>
  )
}

export default styled(PasswordForm)``