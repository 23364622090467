import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Button, MiniCalendar, Note, View, ViewContent, ViewHeader } from 'react-ui'

import { actions as adminActions } from './store'

const showFile = async (data, fileName) => {
  const url = `data:application/pdf;base64,${data}`
  const res = await fetch(url)
  const blob = await res.blob()

  return new Promise(resolve => {
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = data
    link.download = `${fileName}.pdf`
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }, 100)
  })
}

const InlineView = styled(View)`
  top: 72px;
`

const ExportMiniCalendar = styled(MiniCalendar)`
  margin: 0 auto;
`

const ExportButton = styled(Button)`
  max-width: max-content;
  margin: 0 auto;
`

const EntriesNote = styled(Note)`
  width: 320px;
  margin: 16px auto;
  text-align: center;
`

const ExportEntries = ({ className, history, actions }) => {
  const [month, setMonth] = useState(0)
  const [year, setYear] = useState(0)
  const [entries, setEntries] = useState([])

  const getDateStr = (month, year) => {
    const monthStr = String(month)
      .padStart(2, '0')
    return `${year}-${monthStr}`
  }

  const loadEntries = async dateStr => {
    const entries = await actions.admin.exportedEntriesByDate(dateStr)
    setEntries(entries)
  }

  useEffect(() => {
    const now = new Date(Date.now())
    const month = now.getMonth() + 1
    const year = now.getFullYear()
    setMonth(month)
    setYear(year)
    loadEntries(getDateStr(month, year))
  }, [])

  const close = () => {
    history.goBack()
  }

  const handleMonthSelect = month => {
    setMonth(month)
    loadEntries(getDateStr(month, year))
  }

  const handleYearSelect = year => {
    setYear(year)
    loadEntries(getDateStr(month, year))
  }

  const handleExport = async () => {
    const data = await actions.admin.exportEntries(getDateStr(month, year))
    const fileName = `Informationen für die Oberstufe ${getDateStr(month, year)}`
    showFile(data, fileName)
  }

  return (
    <div className={className}>
      <InlineView>
        <ViewHeader
          onBack={close}
        >
          <h1>Einträge exportieren</h1>
        </ViewHeader>
        <ViewContent>
          <ExportMiniCalendar
            month={month}
            year={year}
            onMonthSelect={handleMonthSelect}
            onYearSelect={handleYearSelect}
            headerOnly
          />
          <EntriesNote>
            {entries.length === 0 ? 'Keine' : entries.length} Einträge vorhanden.
          </EntriesNote>
          <ExportButton
            disabled={entries.length === 0}
            onClick={handleExport}
          >
            Exportieren
          </ExportButton>
        </ViewContent>
      </InlineView>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: {
    admin: bindActionCreators(adminActions, dispatch)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(styled(ExportEntries)``)