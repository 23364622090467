import { showErr } from '../../store/app'

const initState = {
  entries: []
}

const entryById = id => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'ENTRY_BY_ID',
    payload: {
      id
    }
  })
  if (showErr(msg, dispatch)) {
    return null
  }
  return msg.payload.entry
}

const putEntry = entry => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'PUT_ENTRY',
    payload: {
      entry
    }
  })
  if (showErr(msg, dispatch)) {
    return null
  }
  return msg.payload.success
}


const entriesByDate = date => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'ENTRIES_BY_DATE',
    payload: {
      date
    }
  })
  if (showErr(msg, dispatch)) {
    return null
  }
  let { entries } = msg.payload
  dispatch({
    type: 'ENTRIES_BY_DATE',
    payload: {
      entries
    }
  })
  return entries
}

const exportedEntriesByDate = date => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'ENTRIES_BY_DATE',
    payload: {
      date
    }
  })
  return msg.payload.entries
}

const removeEntry = id => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'REMOVE_ENTRY',
    payload: {
      id
    }
  })
  if (showErr(msg, dispatch)) {
    return null
  }
  return msg.payload.success
}

const reorderEntries = (entriesDate, entriesIDs) => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'REORDER_ENTRIES',
    payload: {
      entriesDate,
      entriesIDs
    }
  })
  if (showErr(msg, dispatch)) {
    return null
  }
  return msg.payload.success
}

const putPassword = password => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'PUT_DASHBOARD_PASSWORD',
    payload: {
      password
    }
  })
  return msg?.payload?.success
}

const exportEntries = date => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'EXPORT_ENTRIES',
    payload: {
      date
    }
  })
  return msg?.payload?.pdf
}

const putGeneralInformation = generalInformation => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'PUT_GENERAL_INFORMATION',
    payload: {
      generalInformation
    }
  })
  return msg?.payload?.success
}

const getGeneralInformation = () => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'GET_GENERAL_INFORMATION'
  })
  return msg?.payload?.generalInformation || ''
}

const reloadDashboard = () => async (dispatch, getState, send) => {
  await send({
    type: 'RELOAD_DASHBOARD'
  })
}

export const actions = {
  entryById,
  putEntry,
  entriesByDate,
  exportedEntriesByDate,
  removeEntry,
  reorderEntries,
  putPassword,
  exportEntries,
  putGeneralInformation,
  getGeneralInformation,
  reloadDashboard
}

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'ENTRIES_BY_DATE':
      return {
        ...state,
        entries: action.payload.entries
      }
    default:
      return state
  }
}
