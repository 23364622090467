import React from 'react'
import styled, { css } from 'styled-components'

import { SVGIcon } from 'react-ui'

const allYears = ['11', '12', '13']

const LessonText = styled.div`
  font-weight: 400;
`

export const Lesson = styled.div`
  flex-shrink: 0;
  font-size: 35px;
  margin-right: 24px;
  width: 64px;
  text-align: right;
  padding-left: ${p => `${8 * (allYears.length - p.years.length)}px`};
  box-sizing: content-box;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  ${p => p.contentStyle && p.contentStyle};
`

const Inline = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4px;
  
  > *:not(:last-of-type) {
    margin-right: 16px;
  }
`
const Teacher = styled.div`
  font-weight: 400;
`

export const Text = styled.div`
  font-size: 16px;
  white-space: pre-wrap;
  
  &.ql-editor {
    padding: 0 !important;
    height: auto !important;

    > * {
      cursor: unset !important;
    }
    
    ul, ol {
      padding-left: 0;
    }
  }
`

const Icons = styled.div`
  ${SVGIcon} {
    width: 32px;
    height: 32px;
    padding: 0;
  }
`

const YearBorders = styled.div`
  display: flex;
  margin-right: 0.5rem;
`

const YearBorder = styled.div`
  border-left: 8px solid transparent;
  
  ${p => p.year === '11' && css`
    border-left-color: ${p.theme.colors.years['11']};
  `};
  
  ${p => p.year === '12' && css`
    border-left-color: ${p.theme.colors.years['12']};
  `};

  ${p => p.year === '13' && css`
    border-left-color: ${p.theme.colors.years['13']};
  `};
`

const Item = ({
  className,
  item,
  sortable,
  contentStyle,
  onClick,
  onMove
}) => {
  let { lesson } = item

  let lessonText = ''
  if (!/^[1-9](-[1-9])?$/.test(lesson)) {
    lessonText = lesson
    lesson = ''
    if (/pause/i.test(lessonText)) {
      lesson = 'P'
    }
  }

  const getYears = item => {
    const years = []
    for (const year of allYears) {
      if (item.year.includes(year)) {
        years.push(year)
      }
    }
    return years
  }

  const years = getYears(item)

  const renderBorders = () => {
    return (
      <YearBorders>
        {years.map(year => (
          <YearBorder
            key={year}
            year={year}
          />
        ))}
      </YearBorders>
    )
  }

  return (
    <div
      className={className}
    >
      <Content
        onClick={onClick}
        contentStyle={contentStyle}
      >
        {renderBorders()}
        {lesson && (
          <Lesson
            years={years}
          >
            {lesson}
          </Lesson>
        )}
        <div>
          <Inline>
            {lessonText && (
              <LessonText>{lessonText}</LessonText>
            )}
            {item.year && (
              <div>{item.year}</div>
            )}
            {item.subject && (
              <div>{item.subject}</div>
            )}
            {item.teacher && (
              <Teacher>{item.teacher}</Teacher>
            )}
          </Inline>
          <Text
            className='ql-editor'
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      </Content>
      {sortable && (
        <Icons>
          <SVGIcon
            name='arrowUpDown'
            color='#888'
            hovercolor='#333'
            onClick={onMove}
          />
        </Icons>
      )}
    </div>
  )
}

export default styled(Item)`
  display: flex;
  align-items: center;
  padding-right: 16px;
  transition: ease-out 200ms;
  ${p => typeof p.onClick === 'function' ? css`
    cursor: pointer;
  
    &:hover {
      background: #f7f8f2;
    }
  ` : css`
    padding-bottom: 0;
  `}
  
  ${Icons} {
    opacity: 0;
    ${p => p.theme.transition};
    
    ${SVGIcon} {
      padding: 4px;
    }
  }
  
  &:hover {
    ${Icons} {
      opacity: 1;
    }
  }
`
