const initState = {
  showSpinner: false,
  loading: false,
  reloadRoute: ''
}

export const showErr = (msg, dispatch) => {
  if (!msg || !msg.payload || msg.payload.err) {
    dispatch({
      type: 'SHOW_ERR',
      payload: {
        err: msg?.payload?.err || 'server err'
      }
    })
    return true
  }
  return false
}


let spinnerStartTime
const spinnerWaitTime = 500

const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms))

const showSpinner = show => async dispatch => {
  if (show) {
    spinnerStartTime = Date.now()
  } else {
    const diff = Date.now() - spinnerStartTime
    if (diff < spinnerWaitTime) {
      await sleep(spinnerWaitTime - diff)
    }
  }
  dispatch({
    type: 'SHOW_SPINNER',
    payload: {
      show
    }
  })
  if (show) {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true
      }
    })
  }
  await sleep(500)
  if (!show) {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: false
      }
    })
  }
}

const reloadRoute = name => dispatch => {
  dispatch({
    type: 'RELOAD_ROUTE',
    payload: {
      name
    }
  })
}

const validateApiKey = apiKey => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'VALIDATE_ADMIN_API_KEY',
    payload: {
      apiKey
    }
  })
  return msg && msg.payload && msg.payload.valid
}

export const actions = {
  showSpinner,
  reloadRoute,
  validateApiKey
}

export const reducer = (state = initState, action) => {
  switch (action.type) {
  case 'SHOW_SPINNER':
    return {
      ...state,
      showSpinner: action.payload.show
    }
  case 'SET_LOADING':
    return {
      ...state,
      loading: action.payload.loading
    }
  case 'RELOAD_ROUTE':
    return {
      ...state,
      reloadRoute: action.payload.name
    }
  default:
    return state
  }
}
