import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import Quill from 'quill'
import { Button, View, ViewButtons, ViewContent, ViewHeader } from 'react-ui'

import '../../../styles/quill.snow.css'
import FontSizes from './FontSizes'
import Colors from './Colors'
import { actions as adminActions } from '../store'
import control from '../../../services/control'

const StyledView = styled(View)`
  margin-top: 0 !important;
`

const SuccessMessage = styled.div`
  background: ${p => p.theme.colors.success};
  color: #fff;
  width: 640px;
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 16px;
  text-align: center;
`

const Toolbar = styled.div`
  position: relative !important;
  top: 0 !important;
  border: 0 !important;
  width: 640px;
  margin: 0 auto;
`

const Editor = styled.div`
  width: 640px;
  height: 480px !important;
  margin: 0 auto !important;
`

const DeleteButton = styled(Button)`
  background: ${p => p.theme.colors.err};
  margin-right: auto !important;
`

const GeneralInformation = ({ className, actions, onClose }) => {
  const [quill, setQuill] = useState(null)
  const [content, setContent] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const editorRef = useRef(null)
  const toolbarRef = useRef(null)

  useEffect(() => {
    const Size = Quill.import('attributors/style/size')
    Size.whitelist = FontSizes.map(el => `${el[1]}rem`)
    Quill.register(Size, true)
    const quill = new Quill(editorRef.current, {
      modules: {
        toolbar: toolbarRef.current
      },
      theme: 'snow'
    })
    setQuill(quill)
    loadContent(quill)
  }, [])

  const loadContent = async quill => {
    const content = await actions.admin.getGeneralInformation()
    setContent(content)
    quill.setContents([])
    quill.clipboard.dangerouslyPasteHTML(0, content)
  }

  const putGeneralInformation = async generalInformation => {
    setShowSuccess(false)
    await control.sleep(500)
    const success = await actions.admin.putGeneralInformation(generalInformation)
    if (success) {
      setShowSuccess(true)
    }
  }

  const handleSave = () => {
    putGeneralInformation(quill.root.innerHTML)
  }

  const handleRemove = () => {
    quill.setContents([])
    putGeneralInformation('')
  }

  return (
    <div className={className}>
      <StyledView>
        <ViewHeader
          onBack={onClose}
        >
          <h1>Allgemeine Informationen</h1>
        </ViewHeader>
        <ViewContent>
          {showSuccess && (
            <SuccessMessage>Erfolgreich gespeichert</SuccessMessage>
          )}
          <Toolbar
            ref={toolbarRef}
          >
              <span className='ql-formats'>
                <select
                  className='ql-size'
                  title='Schriftgröße'
                  defaultValue='1rem'
                >
                  {FontSizes.map(fontSize => (
                    <option key={fontSize[0]} value={`${fontSize[1]}rem`}>{fontSize[0]}</option>
                  ))}
                </select>
              </span>
            <span className='ql-formats'>
                <button className='ql-bold'/>
                <button className='ql-italic'/>
                <button className='ql-underline'/>
                <button className='ql-strike'/>
              </span>
            <span className="ql-formats">
                <button className="ql-align" value=""></button>
                <button className="ql-align" value="center"></button>
                <button className="ql-align" value="right"></button>
                <button className="ql-align" value="justify"></button>
              </span>
            <span className="ql-formats">
                <select
                  className='ql-color'
                  title='Textfarbe'
                >
                  {Colors.map(color => (
                    <option key={color} value={color}/>
                  ))}
                </select>
                <select
                  className='ql-background'
                  title='Texthintergrund'
                >
                  {Colors.map(color => (
                    <option key={color} value={color}/>
                  ))}
                </select>
              </span>
            <span className="ql-formats">
                <button className='ql-list' value='ordered'/>
                <button className='ql-list' value='bullet'/>
                <span className='ql-align'/>
              </span>
            <span className="ql-formats">
                <button type='button' className='ql-script' value='sub'/>
                <button type='button' className='ql-script' value='super'/>
              </span>
          </Toolbar>
          <Editor
            ref={editorRef}
          />
        </ViewContent>
        <ViewButtons>
          <DeleteButton
            onClick={handleRemove}
          >
            Löschen
          </DeleteButton>
          <Button
            secondary='true'
            onClick={onClose}
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleSave}
          >
            Speichern
          </Button>
        </ViewButtons>
      </StyledView>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: {
    admin: bindActionCreators(adminActions, dispatch)
  }
})

export default styled(connect(
  null,
  mapDispatchToProps
)(GeneralInformation))`
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  
  ${ViewContent} {
    display: flex;
    flex-direction: column;
  }
  
  .ql-toolbar.ql-toolbar {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 79px;
    display: flex;
    justify-content: center;
    background: #fff;
    border-left: 0;
    border-right: 0;
  }
  
  .ql-editor {
    border-top: 1px solid #ccc;
  }
  
  .ql-color,
  .ql-background {
    .ql-picker-options {
      width: 392px !important;
    }
  }
  
  .ql-size {
    width: 52px !important;
  }
  
  .ql-container {
    font-family: Roboto;
    font-weight: 300;
    
    b, strong {
      font-weight: bold;
    }
  }
`
