import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

const MAX_CNT = 10

const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms))

const Content = styled.div` ${p => css`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background:#fff;
  opacity: 0;
  color: #333;
  overflow: hidden;
  transition: ease-out 1000ms;
  
  ${p.show && css`
    opacity: 1;
  `};
  
  ${p.reverse && css`
    background: #000;
    color: #fff;
  `};
`}`

const Counter = styled.div` ${p => css`
  font-size: 3vw;
  font-weight: 400;
`}`

const ScreenSaver = ({ className, onClose }) => {
  const [counter, setCounter] = useState(0)
  const [show, setShow] = useState(false)

  const count = async () => {
    for (let i = 0; i < MAX_CNT; i++) {
      await sleep(1000)
      setCounter(i + 1)
    }
    setShow(false)
    await sleep(1000)
    onClose()
  }

  useEffect(() => {
    setShow(true)
    count()
  }, [])

  const reverse = counter >= 5

  return (
    <div
      className={className}
    >
      <Content
        show={show}
        reverse={reverse}
      >
        {counter > 0 && (
          <Counter>
            {counter}
          </Counter>
        )}
      </Content>
    </div>
  )
}

export default styled(ScreenSaver)``