import React from 'react'
import styled, { css } from 'styled-components'
import Item from '../../components/Item'

const Day = styled.div`
  background: #fffffff2;
  margin-bottom: 8px;
  padding: 8px;
  padding-bottom: 1px;
`

const DayHeader = styled.div`
    font-size: 1.5rem;
`

const contentStyle = css`
  padding: 8px 0;
`

const Items = ({ className, items = {} }) => {
  const days = Object.keys(items)

  const renderDayItems = items => {
    return (
      <div>
        {items.map(item => (
          <Item
            key={item.id}
            item={item}
            contentStyle={contentStyle}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={className}>
      {days.map(day => {
        const dateStr = new Date(day)
          .toLocaleString('de-DE', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        return (
          <Day key={day}>
            <DayHeader>{dateStr}</DayHeader>
            {renderDayItems(items[day])}
          </Day>
        )
      })}
    </div>
  )
}

export default styled(Items)``