import React from 'react'
import styled, { css } from 'styled-components'

const Indicator = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  ${p => p.direction === 'up' && css`
    top: 0;
  `};
  ${p => p.direction === 'down' && css`
    bottom: 0;
  `};
  height: 2px;
  background: #b3b3b3;
  transition: ease-out 200ms;
  cursor: pointer;
`

const Bg = styled.div`
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
`

const MoveTarget = ({ className, direction }) => (
  <div className={className}>
    <Indicator direction={direction} />
    <Bg />
  </div>
)

export default styled(MoveTarget)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  &:hover {
    ${Indicator} {
      background: ${p => p.theme.colors.main};
    }
  }
`