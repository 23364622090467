import React, { Component } from 'react'
import { Menu, View, ViewContent, ViewHeader } from 'react-ui'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import cc from 'classcat'

import { actions as adminActions } from './store'
import Form from './Form'
import SetPassword from './SetPassword'
import Item from '../../components/Item'
import MoveTarget from './MoveTarget'
import GeneralInformation from './GeneralInformation'

let highlightTimer

const Items = styled.ul`
  max-width: 800px;
`

const ItemWrapper = styled.div`
  position: relative;
`

class List extends Component {
  state = {
    entry: {},
    show: {},
    formErr: '',
    setPasswordErr: '',
    menuItems: [{
      action: 'SHOW_GENERAL_INFORMATION',
      text: 'Allgemeine Informationen'
    }, {
      action: 'ADD_ENTRY',
      text: 'Eintrag hinzufügen'
    }],
    settingsItems: [{
      action: 'EXPORT_ENTRIES',
      text: 'Einträge exportieren'
    }, {
      action: 'RELOAD_DASHBOARD',
      text: 'Dashboard neu laden'
    }, {
      action: 'SET_PASSWORD',
      text: 'Dashboard Passwort'
    }],
    highlightIdx: -1,
    showGeneralInformation: false
  }

  reload = async () => {
    const { actions, selectedDate } = this.props
    this.setState({
      formErr: '',
      show: {}
    })
    await actions.admin.entriesByDate(selectedDate)
  }

  handleMenuSelect = action => {
    const { selectedDate, history, actions } = this.props
    if (action === 'ADD_ENTRY') {
      this.setState({
        show: {
          form: true
        },
        entry: {
          id: '',
          date: selectedDate,
          lesson: '',
          year: '',
          subject: '',
          teacher: '',
          text: ''
        },
        showTargets: false,
        targetToMoveIdx: -1
      })
    } else if (action === 'SET_PASSWORD') {
      this.setState({
        show: {
          setPassword: true
        }
      })
    } else if (action === 'EXPORT_ENTRIES') {
      history.push('/admin/export')
    } else if (action === 'SHOW_GENERAL_INFORMATION') {
      this.setState({
        showGeneralInformation: true
      })
    } else if (action === 'RELOAD_DASHBOARD') {
      actions.admin.reloadDashboard()
    }
  }

  handleFormSubmit = async entry => {
    const { actions } = this.props
    const success = await actions.admin.putEntry(entry)
    if (success) {
      await this.reload()
    } else {
      this.setState({
        formErr: 'Eintrag konnte nicht gespeichert werden.'
      })
    }
  }

  hideForm = () => {
    this.setState({
      show: {}
    })
  }

  handleSetPassword = async password => {
    const { actions } = this.props
    if (password.length < 8) {
      this.setState({
        setPasswordErr: 'Das Passwort ist zu kurz'
      })
      return
    }
    const success = await actions.admin.putPassword(password)
    if (success) {
      this.handleHideSetPassword()
    } else {
      this.setState({
        setPasswordErr: 'Passwort konnte nicht gesetzt werden.'
      })
    }
  }

  handleHideSetPassword = () => {
    this.setState({
      show: {},
      setPasswordErr: ''
    })
  }

  showEdit = entry => () => {
    this.setState({
      show: {
        form: true
      },
      entry
    })
  }

  handleEntryRemove = async id => {
    const { actions } = this.props
    const success = await actions.admin.removeEntry(id)
    if (success) {
      await this.reload()
    }
  }

  toggleMoveTargets = idx => () => {
    this.setState(prevState => ({
      showTargets: !prevState.showTargets,
      targetToMoveIdx: idx
    }))
  }

  moveItem = targetIdx => () => {
    const { items, onOrderChange } = this.props
    const { targetToMoveIdx } = this.state
    items.splice(targetIdx, 0, items.splice(targetToMoveIdx, 1)[0])
    onOrderChange(items)
    this.setState({
      showTargets: false
    })
    this.highlightItem(targetIdx)
  }

  highlightItem = idx => {
    this.setState({
      highlightIdx: idx
    }, () => {
      if (highlightTimer) {
        clearTimeout(highlightTimer)
      }
      highlightTimer = setTimeout(() => {
        this.setState({
          highlightIdx: -1
        })
      }, 1000)
    })
  }

  handleGeneralInforamtionClose = () => {
    this.setState({
      showGeneralInformation: false
    })
  }

  render() {
    const { className, items, selectedDate } = this.props
    const {
      entry,
      menuItems,
      settingsItems,
      show,
      formErr,
      setPasswordErr,
      highlightIdx,
      showTargets,
      showGeneralInformation,
      targetToMoveIdx
    } = this.state
    const dateStr = new Date(selectedDate)
      .toLocaleString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    return (
      <div className={className}>
        <View>
          <ViewHeader>
            <h1>{dateStr}</h1>
            <Menu
              items={menuItems}
              icon='plus'
              onSelect={this.handleMenuSelect}
            />
            <Menu
              items={settingsItems}
              icon='settings'
              onSelect={this.handleMenuSelect}
            />
          </ViewHeader>
          <ViewContent>
            <Items>
              {items.map((item, idx) => (
                <ItemWrapper key={item.id}>
                  {showTargets && idx !== targetToMoveIdx && (
                    <div
                      onClick={this.moveItem(idx)}
                    >
                      <MoveTarget
                        direction={idx < targetToMoveIdx ? 'up' : 'down'}
                      />
                    </div>
                  )}
                  <Item
                    className={cc({
                      'highlight': highlightIdx === idx
                    })}
                    item={item}
                    sortable
                    onClick={this.showEdit(item)}
                    onMove={this.toggleMoveTargets(idx)}
                  />
                </ItemWrapper>
              ))}
            </Items>
          </ViewContent>
        </View>
        {show.form && (
          <Form
            entry={entry}
            err={formErr}
            onSubmit={this.handleFormSubmit}
            onClose={this.hideForm}
            onRemove={this.handleEntryRemove}
          />
        )}
        <SetPassword
          show={show.setPassword}
          err={setPasswordErr}
          onSubmit={this.handleSetPassword}
          onClose={this.handleHideSetPassword}
        />
        {showGeneralInformation && (
          <GeneralInformation
            onClose={this.handleGeneralInforamtionClose}
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    admin: bindActionCreators(adminActions, dispatch)
  }
})

export default withRouter(connect(
  null,
  mapDispatchToProps
)(styled(List)`
  ${View} {
    margin-top: 72px;
  }
  
  ${Item} {
    &.highlight {
      color: ${p => p.theme.colors.main};
    }
  }
`))
