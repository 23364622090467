const URL = process.env.NODE_ENV === 'production' ?
  'wss://oberstufe.ggsnk.de/service/api/dashboard' :
  'ws://192.168.178.4:4000/api/dashboard'

let ws
let reconnectAction
let onMessageAction

const connect = () => {
  console.log('>>>> WS CLIENT CONNECT:', URL)
  ws = null
  return new Promise(resolve => {
    ws = new WebSocket(URL)
    ws.onopen = () => {
      setTimeout(resolve)
      if (reconnectAction) {
        reconnectAction()
      }
    }
    ws.onclose = handleClose
    ws.onerror = err => {
      console.log('>>>> WS CLIENT ERR:', err)
    }
    ws.onmessage = (res) => {
      const { data } = res
      const msg = JSON.parse(data)
      onMessageAction(msg)
    }
  })
}

const handleClose = err => {
  console.log('>>>> WS CLIENT ERR:', err)
  setTimeout(connect, 5000)
}

const send = async (msg) => {
  if (!ws) {
    await connect()
  }
  ws.send(JSON.stringify(msg))
}

const setReconnectAction = fn => {
  reconnectAction = fn
}

const setOnMessageAction = fn => {
  onMessageAction = fn
}

export {
  connect,
  send,
  setReconnectAction,
  setOnMessageAction
}
