import React from 'react'
import styled from 'styled-components'
import DateService from '../../services/date'
import Legend from '../../components/Legend'

const LastUpdated = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  
  strong {
    font-weight: 400;
  }
`

const Header = ({ className, lastUpdated }) => {
  return (
    <div className={className}>
      <h1>Informationen für die Oberstufe</h1>
      {lastUpdated && (
        <LastUpdated>
          <div>
            <strong>Stand:</strong>&nbsp;{DateService.nanoTimeToStr(lastUpdated)} Uhr
          </div>
          <Legend/>
        </LastUpdated>
      )}
    </div>
  )
}

export default styled(Header)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${p => p.theme.vars.headerHeight};
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  
  h1 {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    color: #333;
  }
`