import React from 'react'
import styled from 'styled-components'

const Year = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`

const Bar = styled.div`
  margin-right: 0.2rem;
  width: 1rem;
  height: 1rem;
  background: ${p => p.theme.colors.years[p.year]};
`

const Legend = ({ className }) => {
  const years = [11, 12, 13]
  return (
    <div className={className}>
      <strong>Jahrgang:&nbsp;</strong>
      {years.map(year => (
        <Year
          key={year}
        >
          <Bar year={year}/>
          {year}
        </Year>
      ))}
    </div>
  )
}

export default styled(Legend)`
  display: flex;
`