import React from 'react'
import styled from 'styled-components'
import { ButtonStyle } from 'react-ui'

const AddFileButton = styled.div`
  ${ButtonStyle}
  display: inline-block;
  margin: 0;
`

const loadFile = async file => (
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = e => {
      resolve(e.target.result)
    }
    reader.readAsText(file)
  })
)

const handleFileSelect = onSelect => async e => {
  const { target } = e
  const { files } = target
  const fileContent = await loadFile(files[0])
  target.value = null
  onSelect(fileContent)
}

const FileContent = ({ className, buttonText, onSelect }) => (
  <div className={className}>
    <label>
      <input
        type='file'
        multiple
        onChange={handleFileSelect(onSelect)}
      />
      {buttonText && (
        <AddFileButton>
          {buttonText}
        </AddFileButton>
      )}
    </label>
  </div>
)

export default styled(FileContent)`
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`
