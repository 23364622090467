const theme = {
  colors: {
    // main: '#66bb6a',
    main: '#039BE5',
    text: '#333',
    // err: '#e53935',
    err: '#ef5350',
    // success: '#43A047'
    success: '#66BB6A',
    years: {
      11: '#9CCC65',
      12: '#29B6F6',
      13: '#FF7043'
    }
  },
  vars: {
    headerHeight: '72px'
  },
  transition: 'transition: ease-out 200ms'
}

export default theme
