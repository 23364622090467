import React from 'react'
import styled from 'styled-components'

export const Text = styled.h1`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 400;
  color: #333;
  margin: 0;
  display: none;
  
  @media (min-width: 800px) {
    display: block;
  }
`

const Header = ({ className }) => (
  <div className={className}>
    <Text>Informationen für die Oberstufe</Text>
  </div>
)

export default styled(Header)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  height: ${p => p.theme.vars.headerHeight};
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
`
