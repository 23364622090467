import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from './Header'
import { connect, send, setOnMessageAction, setReconnectAction } from '../../services/ws'

import ControlService from '../../services/control'
import Items from './Items'
import GeneralInformation from '../Dashboard/GeneralInformation'
import PasswordForm from './PasswordForm'

const Content = styled.div`
  padding-top: 72px;
`

const Box = styled.div`
  background: #fff;
  padding: 8px;
`

const GeneralInformationBox = styled(Box)`
  margin: 8px 0;
  
`

const BoxHeader = styled.div`
  font-size: 1.5rem;
  margin-bottom: 8px;
`

const loadPassword = () => {
  return localStorage.getItem('password') || ''
}

const savePassword = password => {
  localStorage.setItem('password', password)
}

const Mobile = ({ className }) => {
  const [passwordValidated, setPasswordValidated] = useState(false)
  const [validPassword, setValidPassword] = useState(false)
  const [passwordErr, setPasswordErr] = useState('')
  const [generalInformation, setGeneralInformation] = useState()
  const [items, setItems] = useState([])
  const [lastUpdated, setLastUpdated] = useState()

  useEffect(() => {
    init()
  }, [])

  const update = async () => {
    setPasswordValidated(false)
    setValidPassword(false)
    await send({
      type: 'VALIDATE_DASHBOARD_PASSWORD',
      payload: {
        password: loadPassword()
      }
    })
  }

  const updateData = async () => {
    await ControlService.sleep(300)
    await send({
      type: 'ENTRIES_BY_START_DATE',
      payload: {
        date: new Date().toISOString()
          .split('T')[0],
        password: loadPassword()
      }
    })
    await send({
      type: 'GET_GENERAL_INFORMATION',
      payload: {
        password: loadPassword()
      }
    })
  }

  const init = () => {
    setReconnectAction(update)
    setOnMessageAction(async msg => {
      if (!msg) {
        return
      }
      switch (msg.type) {
        case 'VALIDATE_DASHBOARD_PASSWORD': {
          setPasswordValidated(true)
          const hasValidPassword = msg.payload.validPassword
          setValidPassword(hasValidPassword)
          if (!hasValidPassword) {
            return
          }
          await updateData()
          break
        }
        case 'DASHBOARD_LOGIN': {
          if (!msg?.payload?.validPassword) {
            setPasswordErr('Ungültiges Passwort')
            return
          }
          setValidPassword(true)
          savePassword(msg.payload.password)
          await updateData()
          break
        }
        case 'RELOAD_DASHBOARD': {
          window.location.reload()
          break
        }
        case 'UPDATE_DASHBOARD': {
          await update()
          break
        }
        case 'ENTRIES_BY_START_DATE': {
          setItems(msg.payload.items || [])
          setLastUpdated(msg.payload.lastUpdated)
          break
        }
        case 'GET_GENERAL_INFORMATION': {
          setGeneralInformation(msg.payload.generalInformation)
          break
        }
        default: {
        }
      }
    })
    connect()
  }

  const handlePasswordSubmit = async password => {
    setPasswordErr('')
    await send({
      type: 'DASHBOARD_LOGIN',
      payload: {
        password
      }
    })
  }

  return (
    <div className={className}>
      <Header lastUpdated={lastUpdated}/>
      <Content>
        {passwordValidated && !validPassword && (
          <PasswordForm
            err={passwordErr}
            onSubmit={handlePasswordSubmit}
          />
        )}
        {generalInformation && (
          <GeneralInformationBox>
            <BoxHeader>Allgemeine Informationen</BoxHeader>
            <GeneralInformation
              generalInformation={generalInformation}
            />
          </GeneralInformationBox>
        )}
        <Items
          items={items}
        />
      </Content>
    </div>
  )
}

export default styled(Mobile)`
  min-height: 100vh;
  background: #7db0e1;
`