import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  SVGIcon,
  TextArea,
  ViewButtons,
  ViewContent,
  ViewHeader
} from 'react-ui'
import styled from 'styled-components'
import RemoveConfirmDialog from '../RemoveConfirmDialog'
import Text from './Text'

const DeleteButton = styled(Button)`
  margin-right: auto !important;
  background: ${p => p.theme.colors.err};
  padding: 0;
  
  ${SVGIcon} {
    padding: 10px;
    width: 48px;
    height: 48px;
  }
`

const ModalViewContent = styled(ViewContent)`
  max-height: calc(100vh - 288px);
`

const defaultEntry = () => ({
  id: '',
  lesson: '',
  year: '',
  subject: '',
  teacher: '',
  text: ''
})

const Form = ({
  className,
  entry: initEntry,
  err,
  onSubmit,
  onClose,
  onRemove
}) => {
  const [entry, setEntry] = useState(initEntry || defaultEntry())
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus()
    }, 500)
  }, [])

  const update = e => {
    const { name, value } = e.target
    setEntry({
      ...entry,
      [name]: value
    })
  }

  const showRemoveConfirmDialog = () => {
    setShowRemoveConfirm(true)
  }

  const hideRemoveConfirmDialog = () => {
    setShowRemoveConfirm(false)
  }

  const removeInfo = () => {
    onRemove(entry.id)
  }

  const submitForm = () => {
    onSubmit(entry)
  }

  const renderForm = () => (
    <form>
      <Input
        name='lesson'
        label='Stunde / Überschrift'
        value={entry.lesson}
        onChange={update}
        ref={inputRef}
      />
      <Input
        name='year'
        label='Jahrgang'
        value={entry.year}
        onChange={update}
      />
      <Input
        name='subject'
        label='Fach'
        value={entry.subject}
        onChange={update}
      />
      <Input
        name='teacher'
        label='Lehrer'
        value={entry.teacher}
        onChange={update}
      />
      <Text
        text={entry.text}
        onChange={update}
      />
    </form>
  )

  const renderContent = () => (
    <>
      <ViewHeader>
        <h1>Info hinzufügen</h1>
      </ViewHeader>
      <ModalViewContent>
        {renderForm()}
        {showRemoveConfirm && (
          <RemoveConfirmDialog
            onClose={hideRemoveConfirmDialog}
            onRemove={removeInfo}
          />
        )}
      </ModalViewContent>
      <ViewButtons>
        {entry.id && (
          <DeleteButton
            type='button'
            onClick={showRemoveConfirmDialog}
          >
            <SVGIcon
              name='remove'
              color='#fff'
              title='Löschen'
            />
          </DeleteButton>
        )}
        <Button
          secondary='true'
          onClick={onClose}
        >
          Abbrechen
        </Button>
        <Button
          onClick={submitForm}
        >
          {entry.id ? 'Speichern' : 'Hinzufügen'}
        </Button>
      </ViewButtons>
    </>
  )

  return (
    <div className={className}>
      <Modal
        show
        attachToBody
        width='800'
        top='96'
        onClose={onClose}
      >
        {renderContent()}
      </Modal>
    </div>
  )
}

export default styled(Form)`  
  ${TextArea} {
    margin-bottom: 0;
  }
`
