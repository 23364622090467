import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose
} from 'redux'
import thunk from 'redux-thunk'

import send from '../services/send'

import { reducer as appReducer } from './app'
import { reducer as adminReducer } from '../Views/Admin/store'

const middleware = [thunk.withExtraArgument(send)]
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => createStore(
  combineReducers({
    app: appReducer,
    admin: adminReducer
  }),
  composeEnhancers(
    applyMiddleware(...middleware)
  )
)
