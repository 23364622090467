import React, { Component } from "react"
import posed from "react-pose"
import { Modal, Button, ViewContent } from "react-ui"
import styled from "styled-components"

const Text = styled.div``

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  
  ${Button} {
    margin: 0;
  }
`

const CancelButton = styled(Button)`
  color: #555;
  margin-right: 16px !important;
  
  &:hover {
    color: #333;
  }
`

const DeleteButton = styled(Button)`
  background: ${p => p.theme.colors.err};
`

class RemoveConfirmDialog extends Component {
  state = {
    show: false
  }

  componentDidMount() {
    this.setState({
      show: true
    })
  }

  close = () => {
    this.setState({
      show: false
    })
  }

  render() {
    const { className, onClose, onRemove } = this.props
    const { show } = this.state
    return (
      <div className={className}>
        <Modal
          show={show}
          width="480"
          top="208"
          attachToBody
          onClose={onClose}
        >
          <ViewContent>
            <Text>
              Klicken Sie auf den <strong>Löschen</strong> Button um den Löschvorgang zu
              bestätigen.
            </Text>
            <Buttons>
              <CancelButton
                secondary="true"
                onClick={this.close}
              >
                Abbrechen
              </CancelButton>
              <DeleteButton
                onClick={onRemove}
              >
                Löschen
              </DeleteButton>
            </Buttons>
          </ViewContent>
        </Modal>
      </div>
    )
  }
}

export default styled(RemoveConfirmDialog)``
