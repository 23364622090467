import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MiniCalendar } from 'react-ui'
import { Route } from 'react-router-dom'
import styled from 'styled-components'

import FileContent from '../../components/FileContent'
import GlobalError from '../../components/GlobalError'
import List from './List'
import ExportEntries from './ExportEntries'
import Header from '../Header'

import { actions as appActions } from '../../store/app'
import { actions as adminActions } from './store'

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  overflow-y: auto;
`

const AdminKeyUploader = styled(FileContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
`

const EntriesMiniCalendar = styled(MiniCalendar)`
  position: fixed;
  z-index: 1;
  right: 32px;
  margin-top: 86px;
`

class Admin extends Component {
  state = {
    apiKeyChecked: false,
    validApiKey: false,
    showErr: false,
    selectedDate: '',
    entriesLoaded: false
  }

  componentDidMount() {
    this.checkHasValidApiKey()
  }

  async componentDidUpdate(prevProps, prevState) {
    const { validApiKey, selectedDate } = this.state
    const { actions } = this.props
    if (!validApiKey) {
      return
    }
    if (!selectedDate) {
      this.setState({
        selectedDate: new Date().toISOString()
          .split('T')[0]
      })
    } else if (prevState.selectedDate !== selectedDate) {
      await actions.admin.entriesByDate(selectedDate)
    }
  }

  checkHasValidApiKey = async () => {
    const { actions } = this.props
    const apiKey = sessionStorage.getItem('apiKey')
    const validApiKey = await actions.app.validateApiKey(apiKey)
    return new Promise(resolve => {
      this.setState(() => ({
        apiKeyChecked: true,
        validApiKey
      }), resolve)
    })
  }

  setApiKey = apiKey => {
    apiKey = apiKey.trim()
    this.setState(() => ({
      showErr: false
    }), async () => {
      sessionStorage.setItem('apiKey', apiKey)
      await this.checkHasValidApiKey()
      const { validApiKey } = this.state
      if (!validApiKey) {
        this.setState(() => ({
          showErr: true
        }))
      }
    })
  }

  closeErr = () => {
    this.setState(() => ({
      showErr: false
    }))
  }

  handleDateSelect = date => {
    this.setState({
      selectedDate: date
    })
  }

  handleOrderChange = async items => {
    const { actions } = this.props
    const { selectedDate } = this.state
    const ids = items.map(item => item.id)
    const success = await actions.admin.reorderEntries(selectedDate, ids)
    if (!success) {
      return
    }
    await actions.admin.entriesByDate(selectedDate)
  }

  render() {
    const { className } = this.props
    const {
      apiKeyChecked,
      validApiKey,
      showErr,
      selectedDate
    } = this.state
    const { entries } = this.props
    if (!apiKeyChecked) {
      return null
    }
    if (!validApiKey) {
      return (
        <div className={className}>
          {showErr && (
            <GlobalError
              onClose={this.closeErr}
            >
              Dieser Schlüssel ist ungültig
            </GlobalError>
          )}
          <AdminKeyUploader
            buttonText='Schlüssel hinzufügen'
            onSelect={this.setApiKey}
          />
        </div>
      )
    }
    return (
      <div className={className}>
        <Header/>
        <Content>
          <List
            items={entries}
            selectedDate={selectedDate}
            onOrderChange={this.handleOrderChange}
          />
          <EntriesMiniCalendar
            select={selectedDate}
            onSelect={this.handleDateSelect}
          />
          <Route
            path='/admin/export'
            component={ExportEntries}
          />
        </Content>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  entries: state.admin.entries
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch),
    admin: bindActionCreators(adminActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Admin)`
  display: flex;
  justify-content: center;
  flex: 1;
`)
