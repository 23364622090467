import React, {Component} from 'react'
import styled, {css} from 'styled-components'
import Item, {Content as ItemContent, Lesson as ItemLesson, Text as ItemText} from '../../components/Item'
import Legend from '../../components/Legend'
import DateService from '../../services/date'
import {connect, send, setOnMessageAction, setReconnectAction} from '../../services/ws'

import '../../styles/quill.snow.css'
import Header, {Text as HeaderText} from '../Header'
import bg from './bg.webp'
import GeneralInformation from './GeneralInformation'
import ScreenSaver from './ScreenSaver'

const INIT_WAIT_TIME = process.env.NODE_ENV === 'production' ? 5000 : 0
const SCREEN_SAVER_TIME = process.env.NODE_ENV === 'production' ? 2 * 60 * 60 * 1000 : 0

const HEADER_HEIGHT = '3.75vw'

const defaultState = () => {
  return {
    password: '',
    passwordErr: '',
    passwordValidated: false,
    validPassword: false,
    items: {},
    params: [],
    lastUpdated: 0,
    show: false,
    alignStart: false,
    showScreenSaver: false,
    generalInformation: ''
  }
}

const searchToObj = search => {
  if (search === '') {
    return {}
  }
  return search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({
      ...obj,
      [decodeURIComponent(key)]: decodeURIComponent(value)
    }), {})
}

const DashboardHeader = styled(Header)`
  height: ${HEADER_HEIGHT};
  ${HeaderText} {
    font-size: 1.6vw;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${HEADER_HEIGHT};
  overflow-y: auto;
`

const Day = styled.li`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fffffff2;
  margin-top: 0.8vw;
  margin-right: 0.8vw;
  padding: 0.8vw;
  padding-bottom: 1px;
  border-radius: 4px;
  width: calc((100vw - 5 * 0.8vw) / 4);
  
  &.large {
    width: 50vw;
  }
`

const Days = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  align-content: ${p => p.alignStart ? 'flex-start' : 'center'};
  opacity: 0;
  ${p => p.theme.transition};
  
  ${p => p.show && css`
    opacity: 1;
  `}
`

const DayHeader = styled.div`
    font-size: 1.5vw;
`

const Items = styled.ul`
  margin-bottom: 16px;
`

const DayItem = styled(Item)`
  font-size: 0.94vw;
   
  ${ItemLesson} {
    font-size: 1.4vw;
    min-width: 2.9vw;
    max-width: 2.9vw;
    border-left: 0.4vw solid transparent;
  }
  
  ${ItemText} {
    font-size: 0.8vw;
  }
  
  ${ItemContent} {
    padding: 0;
    padding-top: 0.8vw;
  }
  
`

const LastUpdated = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: ${HEADER_HEIGHT};
  font-size: 1vw;
  margin-right: 0.8vw;
  
  strong {
    font-weight: 400;
  }
`

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class Dashboard extends Component {
  state = defaultState()

  daysRef = React.createRef()

  async componentDidMount() {
    document.documentElement.style.fontSize = '32px'
    await sleep(INIT_WAIT_TIME)
    await this.setParams()
    setReconnectAction(this.update)
    setOnMessageAction(async msg => {
      if (!msg) {
        return
      }
      switch (msg.type) {
        case 'RELOAD_DASHBOARD': {
          window.location.reload()
          break
        }
        case 'UPDATE_DASHBOARD': {
          this.update()
          break
        }
        case 'ENTRIES_BY_START_DATE': {
          this.setState(() => ({
            show: false,
            alignStart: false,
            items: msg.payload.items || [],
            lastUpdated: msg.payload.lastUpdated
          }))
          await sleep(1000)
          this.setState(() => ({
            show: true,
            alignStart: this.hasOverflow()
          }))
          break
        }
        case 'GET_GENERAL_INFORMATION': {
          this.setState({
            generalInformation: msg.payload.generalInformation
          })
          break
        }
        default: {
        }
      }
    })
    connect()
    this.loopScreensaver()
  }

  loopScreensaver = async () => {
    while (SCREEN_SAVER_TIME) {
      await sleep(SCREEN_SAVER_TIME)
      this.setState(() => ({
        showScreenSaver: true
      }))
    }
  }

  setParams = async () => {
    const {search} = window.location
    const params = searchToObj(search)
    return new Promise(resolve => {
      this.setState({
        params
      }, resolve)
    })
  }

  update = async () => {
    const {params} = this.state
    this.setState(() => ({
      show: false
    }))
    await sleep(300)
    await send({
      type: 'ENTRIES_BY_START_DATE',
      payload: {
        date: new Date().toISOString()
          .split('T')[0],
        apiKey: params.apiKey
      }
    })
    await send({
      type: 'GET_GENERAL_INFORMATION',
      payload: {
        apiKey: params.apiKey
      }
    })
  }

  hasOverflow = () => {
    return this.daysRef.current.scrollWidth > this.daysRef.current.clientWidth
  }

  handleScreenSaverClose = () => {
    this.setState(() => ({
      showScreenSaver: false
    }))
  }

  renderDayItems = items => {
    return (
      <Items>
        {items.map(item => (
          <li key={item.id}>
            <DayItem
              item={item}
            />
          </li>
        ))}
      </Items>
    )
  }

  render() {
    const {className} = this.props
    const {
      items,
      lastUpdated,
      params,
      show,
      alignStart,
      showScreenSaver,
      generalInformation
    } = this.state
    const days = Object.keys(items)
    return (
      <div className={className}>
        <DashboardHeader/>
        <Content>
          {lastUpdated && (
            <LastUpdated>
              <div>
                <strong>Stand:</strong>&nbsp;{DateService.nanoTimeToStr(lastUpdated)} Uhr
              </div>
              <Legend/>
            </LastUpdated>
          )}
          <Days
            ref={this.daysRef}
            show={show}
            alignStart={alignStart}
            kiosk={params.kiosk}
          >
            {generalInformation && (
              <Day
                key='generalInformation'
              >
                <DayHeader>Allgemeine Informationen</DayHeader>
                <GeneralInformation
                  generalInformation={generalInformation}
                />
              </Day>
            )}
            {days.map(day => {
              const dateStr = new Date(day)
                .toLocaleString('de-DE', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })
              return (
                <Day key={day}>
                  <DayHeader>{dateStr}</DayHeader>
                  {this.renderDayItems(items[day])}
                </Day>
              )
            })}
          </Days>
          {showScreenSaver && (
            <ScreenSaver
              onClose={this.handleScreenSaverClose}
            />
          )}
        </Content>
      </div>
    )
  }
}

export default styled(Dashboard)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
  background: #7db0e1;
  padding: 0.8vw;
  padding-top: 0;
  background: url(${bg});
  background-size: cover;
`
