import React, { useEffect, useRef } from 'react'
import { LabelText } from 'react-ui'
import styled from 'styled-components'

import Quill from 'quill'

import '../../../styles/quill.snow.css'

const TextLabelText = styled(LabelText)`
  margin-bottom: 8px;
`

const Editor = styled.div`
  .ql-editor {
    height: 160px;
    font-size: 16px;
    
    b, strong {
      font-weight: bold;
    }
    
    ul, ol {
      padding-left: 0;
    }
  }
`

const Text = ({ className, text, onChange }) => {
  const editorRef = useRef(null)
  const onChangeRef = useRef()

  useEffect(() => {
    onChangeRef.current = onChange
  })

  useEffect(() => {
    const quill = new Quill(editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ]
      }
    })
    const onTextChange = () => {
      const target = {
        name: 'text',
        value: quill.root.innerHTML
      }
      onChangeRef.current({ target })
    }
    quill.on('text-change', onTextChange)
    quill.setContents([])
    quill.clipboard.dangerouslyPasteHTML(0, text)
    quill.root.blur()
    return () => {
      quill.off('text-change', onTextChange)
    }
  }, [])

  return (
    <div className={className}>
      <TextLabelText>Text</TextLabelText>
      <Editor
        ref={editorRef}
      />
    </div>
  )
}

export default styled(Text)``