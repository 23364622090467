import React from 'react'
import styled from 'styled-components'
import { Backdrop, SVGIcon } from 'react-ui'

const ErrBackdrop = styled(Backdrop)`
  z-index: 2000;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Content = styled.div`
  position: fixed;
  z-index: 2001;
  left: 0;
  right: 0;
  top: 160px;
  background: ${p => p.theme.colors.err};
  max-width: 800px;
  margin: 0 auto;
`

const Text = styled.div`
  color: #fff;
  font-size: 20px;
  padding: 80px;
  padding-top: 16px;
  text-align: center;
`

const Error = ({ className, children, onClose }) => (
  <div className={className}>
    <Content>
      <Header>
        <SVGIcon
          name='close'
          color='#fff'
          onClick={onClose}
        />
      </Header>
      <Text>{children}</Text>
    </Content>
    <ErrBackdrop
      onClick={onClose}
    />
  </div>
)

export default styled(Error)``
