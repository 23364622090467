import cc from 'classcat'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'

const GeneralInformation = ({className, generalInformation}) => {
  const elRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      updateSize()
    })
  }, [generalInformation])

  const updateSize = () => {
    const el = elRef.current
    const parentEl = el.parentElement

    el.style.columnCount = 1
    parentEl.classList.remove('large')

    const hasOverflow = el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth
    if (!hasOverflow) {
      return
    }

    parentEl.classList.add('large')

    let columns = 1
    let hasWidthOverflow
    do {
      columns++
      el.style.columnCount = columns
      hasWidthOverflow = el.scrollWidth > el.clientWidth
    } while (hasWidthOverflow && columns < 4)
  }

  return (
    <div
      className={cc({
        [className]: true,
        'ql-editor': true
      })}
      ref={elRef}
      dangerouslySetInnerHTML={{__html: generalInformation}}
    />
  )
}

export default styled(GeneralInformation)`
  margin-bottom: 0.8vw;
  padding: 0 !important;
  height: auto !important;
  font-size: 1rem !important;
`