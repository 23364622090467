const URL = process.env.NODE_ENV === 'production' ?
  'https://oberstufe.ggsnk.de/service/api' :
  'http://192.168.178.4:4000/api'

const addApiKey = msg => {
  const apiKey = sessionStorage.getItem('apiKey')
  return {
    ...msg,
    payload: {
      ...msg.payload,
      apiKey: apiKey || ''
    }
  }
}

const send = async msg => {
  msg = addApiKey(msg)
  let responseMessage
  try {
    const response = await fetch(URL, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(msg)
    })
    responseMessage = response.json()
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error(err)
  }
  return responseMessage
}

export default send
